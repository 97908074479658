import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { all, put, takeLatest } from 'redux-saga/effects'
import { DocumentInstance } from '@pbt/pbt-ui-components'

import * as API from '../../api'
import type { RootState } from '../index'
// @ts-ignore
import requestAPI from '../sagas/requestAPI'

export const FETCH_DOCUMENTS_LIST_V2 = 'questions/FETCH_DOCUMENTS_LIST_V2'
export const FETCH_DOCUMENTS_LIST_V2_SUCCESS =
  'questions/FETCH_DOCUMENTS_LIST_V2_SUCCESS'
export const FETCH_DOCUMENTS_LIST_V2_FAILURE =
  'questions/FETCH_DOCUMENTS_LIST_V2_FAILURE'

// TODO SIGN DOCUMENT V2

type DocumentsV2State = {
  error: string | null
  isLoading: boolean
  list: DocumentInstance[]
}

const INITIAL_STATE: DocumentsV2State = {
  list: [],
  error: null,
  isLoading: false,
}

const documentsV2Slice = createSlice({
  name: 'documentsV2',
  initialState: INITIAL_STATE,
  reducers: {
    fetchDocumentsListV2: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ appointmentId: string }>,
    ) => {
      state.isLoading = true
      state.error = null
      state.list = []
    },
    fetchDocumentsListV2Success: (
      state,
      action: PayloadAction<DocumentInstance[]>,
    ) => {
      state.list = action.payload
      state.isLoading = false
    },
    fetchDocumentsListV2Failure: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export const documentsV2Reducer = documentsV2Slice.reducer

export const {
  fetchDocumentsListV2,
  fetchDocumentsListV2Success,
  fetchDocumentsListV2Failure,
} = documentsV2Slice.actions

export const getDocumentsV2 = (state: RootState) => state.documentsV2
export const getDocumentsV2List = (state: RootState): DocumentInstance[] =>
  getDocumentsV2(state).list
export const getDocumentsV2IsLoading = (state: RootState): boolean =>
  getDocumentsV2(state).isLoading
export const getDocumentsV2Error = (state: RootState): string | null =>
  getDocumentsV2(state).error

export function* sagaFetchDocumentsListV2({
  payload: { appointmentId },
}: ReturnType<typeof fetchDocumentsListV2>) {
  try {
    const list = yield* requestAPI(API.fetchDocumentsListV2, appointmentId)
    yield put(fetchDocumentsListV2Success(list))
  } catch (error) {
    yield put(fetchDocumentsListV2Failure(error as string))
  }
}

function* watchFetchDocumentsList() {
  yield takeLatest(fetchDocumentsListV2.type, sagaFetchDocumentsListV2)
}

export function* documentsV2Saga() {
  yield all([watchFetchDocumentsList()])
}
