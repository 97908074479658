import type { UpdateNPSData } from '../store/duck/nps'
import { Appointment } from '../types/entities/appointments'
import { BraintreePaymentData } from '../types/entities/memberships'
// @ts-ignore
import { getAPIPath, request } from './core'
import * as Schema from './schemas'

// @ts-ignore
export * from './core'

export const fetchBusiness = (businessId: string) =>
  request(getAPIPath('kiosk/business'), {
    method: 'GET',
    params: { businessId },
  })

export const fetchClientByPhone = (
  businessId: string,
  phone: string,
  appointmentId: string,
  withGlobalPreferences?: boolean,
) =>
  request(getAPIPath('kiosk/clientByPhone'), {
    method: 'GET',
    params: { businessId, phone, appointmentId, withGlobalPreferences },
  })
export const fetchClientByEmail = (
  businessId: string,
  email: string,
  appointmentId: string,
) =>
  request(getAPIPath('kiosk/clientByEmail'), {
    method: 'GET',
    params: { businessId, email, appointmentId },
  })
export const fetchClientById = (
  businessId: string,
  clientId: string,
  appointmentId: string,
) =>
  request(getAPIPath('kiosk/clientById'), {
    method: 'GET',
    params: { businessId, id: clientId, appointmentId },
  })
export const createClient = (
  businessId: string,
  client: any,
  forceExisting: boolean,
) =>
  request(getAPIPath('kiosk/createClient'), {
    method: 'POST',
    data: client,
    params: { businessId, forceExisting },
  })
export const editClient = (businessId: string, client: any) =>
  request(getAPIPath(`kiosk/updateClient/${client.id}`), {
    method: 'PUT',
    data: client,
    params: { businessId },
  })
export const verifyClientEmailForBoop = (
  businessId: string,
  clientId: string,
  email: string,
) =>
  request(getAPIPath('kiosk/email/verifyForBoop'), {
    method: 'GET',
    params: { businessId, clientId, email },
  })

export const verifyClientEmailByToken = (
  businessId: string,
  clientId: string,
  token: string,
) =>
  request(getAPIPath('auth/email_verification/verify'), {
    method: 'POST',
    params: { businessId },
    data: { id: clientId, token },
  })

export const validateClientEmail = (businessId: string, clientId: string) =>
  request(getAPIPath(`kiosk/validate-email/${clientId}`), {
    method: 'POST',
    params: { businessId },
  })

export const fetchConstants = (businessId: string) =>
  request(getAPIPath('kiosk/constants'), {
    method: 'GET',
    params: { businessId },
  })
export const fetchAvailableSlotsV2 = (
  businessId: string,
  businessAppointmentTypeId: string,
  date: string,
) =>
  request(getAPIPath('kiosk/v2/appointments/availability'), {
    method: 'GET',
    params: { businessId, businessAppointmentTypeId, date },
  })
export const fetchAvailableStuffV2 = (
  businessId: string,
  businessAppointmentTypeId: string,
  date: string,
) =>
  request(getAPIPath('kiosk/v2/appointments/availableStaff'), {
    method: 'GET',
    params: { businessId, businessAppointmentTypeId, date },
  })
export const fetchAppointment = (businessId: string, appointmentId: string) =>
  request(getAPIPath(`kiosk/appointments/${appointmentId}`), {
    method: 'GET',
    params: { businessId },
  })
export const createAppointment = (businessId: string, appointment: string) =>
  request(getAPIPath('kiosk/appointments'), {
    method: 'POST',
    data: appointment,
    params: { businessId },
  })
export const editAppointment = (businessId: string, appointment: Appointment) =>
  request(getAPIPath(`kiosk/appointments/${appointment.id}`), {
    method: 'PUT',
    data: appointment,
    params: { businessId },
  })
export const createWaitlistAppointment = (
  businessId: string,
  appointment: Appointment,
  isEmbedded: boolean,
) => {
  const params = isEmbedded ? { businessId } : { businessId, onSite: true }

  return request(getAPIPath('kiosk/appointments/waitlist'), {
    method: 'POST',
    data: appointment,
    params,
  })
}
export const checkInForAppointment = (
  businessId: string,
  appointmentId: string,
  arrived: boolean,
) =>
  request(getAPIPath(`kiosk/appointments/${appointmentId}/checkIn`), {
    method: 'PUT',
    params: { businessId, arrived },
  })
export const fetchWaitlist = (businessId: string) =>
  request(getAPIPath('kiosk/waitlist'), {
    method: 'GET',
    params: { businessId },
  })

export const createPatient = (
  businessId: string,
  clientId: string,
  patient: any,
) =>
  request(getAPIPath(`kiosk/client/${clientId}/createPatient`), {
    method: 'POST',
    data: patient,
    params: { businessId },
  })
export const editPatient = (
  businessId: string,
  clientId: string,
  patient: any,
) =>
  request(getAPIPath(`kiosk/client/${clientId}/updatePatient/${patient.id}`), {
    method: 'PUT',
    data: patient,
    params: { businessId },
  })

export const fetchQuestionsLog = (businessId: string, appointmentId: string) =>
  request(
    getAPIPath(`kiosk/appointments/${appointmentId}/customQuestions/log`),
    { method: 'GET', params: { businessId, appointmentId } },
  )
export const saveQuestionsLog = (
  businessId: string,
  appointmentId: string,
  questionsLog: any,
) =>
  request(
    getAPIPath(`kiosk/appointments/${appointmentId}/customQuestions/log`),
    {
      method: 'POST',
      data: questionsLog,
      params: { businessId, appointmentId },
    },
  )
export const updateAppointmentState = (
  businessId: string,
  appointmentId: string,
  stateId: string,
) =>
  request(getAPIPath(`kiosk/appointments/${appointmentId}/state/${stateId}`), {
    method: 'PUT',
    params: { businessId },
  })
export const fetchAppointmentIcsUrl = (
  businessId: string,
  appointmentId: string,
) =>
  request(getAPIPath(`kiosk/appointments/${appointmentId}/ics`), {
    method: 'GET',
    params: { businessId },
  })
export const sendAppointmentConfirmationEmail = (
  businessId: string,
  appointmentId: string,
  message: string,
) =>
  request(
    getAPIPath(`kiosk/appointments/${appointmentId}/v2/email/confirmation`),
    { method: 'POST', data: message, params: { businessId } },
  )
export const sendZoomLinkMessage = (
  businessId: string,
  appointmentId: string,
  message: string,
) =>
  request(getAPIPath(`kiosk/appointments/${appointmentId}/email/zoomLink`), {
    method: 'POST',
    data: message,
    params: { businessId },
  })

export const fetchDocumentsList = (businessId: string, appointmentId: string) =>
  request(getAPIPath(`kiosk/document/${appointmentId}`), {
    method: 'GET',
    params: { businessId },
  })
export const fetchDocumentsListV2 = (
  businessId: string,
  appointmentId: string,
) =>
  request(getAPIPath(`kiosk/v2/document/${appointmentId}`), {
    method: 'GET',
    params: { businessId },
  })
export const signDocument = (
  businessId: string,
  documentId: string,
  object: any,
) =>
  request(getAPIPath(`kiosk/document/sign/${documentId}`), {
    method: 'PUT',
    data: object,
    params: { businessId },
  })
// TODO signDocumentV2
export const fetchPaymentLinkData = (token: string) =>
  request(getAPIPath(`online/payment/${token}`), { method: 'GET' })
export const createPayment = (token: string, data: any) =>
  request(getAPIPath(`online/payment/${token}/createPayment`), {
    method: 'POST',
    data,
  })
export const emailPayment = (token: string, email: string) =>
  request(getAPIPath(`online/payment/${token}/sendEmail`), {
    method: 'POST',
    data: { email },
  })
export const fetchWellnessPlansVersion = (businessId: string) =>
  request(getAPIPath('membership/active'), {
    method: 'GET',
    params: { businessId },
  })
export const fetchMembershipSignUpData = (
  businessId: string,
  clientId: string,
) =>
  request(getAPIPath('membership/signUpToken'), {
    method: 'GET',
    params: { businessId, clientId },
  })
export const fetchMembershipSignUpDataForToken = (token: string) =>
  request(getAPIPath(`membership/signUp/${token}`), { method: 'GET' })
export const updateMembershipSignUpTokenSelection = (
  token: string,
  selection: any,
) =>
  request(
    getAPIPath(`membership/signUp/${encodeURIComponent(token)}/selection`),
    { method: 'POST', data: selection },
  )
export const storeMembershipSignUpTokenSelection = (
  token: string,
  selection: any,
) =>
  request(
    getAPIPath(`membership/signUp/${encodeURIComponent(token)}/storeSelection`),
    { method: 'POST', data: selection },
  )

export const checkMembershipSubscriptionStatus = (token: string) =>
  request(getAPIPath(`membership/signUp/${token}/check`), { method: 'GET' })
export const sendMembershipWelcomeMessage = (clientId: string, email: string) =>
  request(getAPIPath('membership/welcomeMessage'), {
    method: 'POST',
    params: { email, clientId },
  })
export const sendMembershipWelcomeMessageV2 = (
  clientId: string,
  email: string,
  selection: { patientId: string; planId: string }[],
) =>
  request(getAPIPath('v2/membership/welcomeMessage'), {
    method: 'POST',
    data: { email, clientId, patientWPlanIdPairs: selection },
  })
export const confirmFreeMembershipSignUp = (token: string) =>
  request(getAPIPath(`membership/signUp/${token}/confirmFree`), {
    method: 'POST',
  })

export const fetchPaymentsPublicKey = () =>
  Promise.resolve({
    publicKey: 'pk_test_12345',
  })

export const fetchAppointmentConfirmationDataForToken = (
  businessId: string,
  token: string,
) =>
  request(getAPIPath('kiosk/appointments/confirmation/token/data'), {
    method: 'GET',
    params: { businessId, token },
  })

export const editConfirmableAppointment = (
  businessId: string,
  appointment: string,
  confirmationLinkToken: string,
) =>
  request(getAPIPath('kiosk/appointments'), {
    method: 'PUT',
    data: appointment,
    params: { businessId, confirmationLinkToken },
  })

export const fetchNPSData = (token: string, score: number) =>
  request(getAPIPath(`v1/nps/${token}?score=${score}`), { method: 'GET' })

export const updateNPSData = ({
  token,
  updateNpsData,
}: {
  token: string
  updateNpsData: UpdateNPSData
}) =>
  request(getAPIPath(`v1/nps/${token}`), { method: 'PUT', data: updateNpsData })

export const fetchAppointmentTypes = (businessId: string, from = 0, to = 200) =>
  request(
    getAPIPath('kiosk/businessAppointmentTypes'),
    { method: 'GET', params: { businessId, from, to } },
    true,
    Schema.appointmentTypeList,
  )

export const postBraintreeMembershipSignup = (
  token: string,
  braintreeData: BraintreePaymentData,
) =>
  request(
    getAPIPath(`membership/signUp/${encodeURIComponent(token)}/braintree`),
    { method: 'POST', data: braintreeData },
  )
